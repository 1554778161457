import React from 'react'

export default function Logo(_props: {color?: string}) {
  return (
    // <img
    //   src="https://d2nmg3qradgpe0.cloudfront.net/cdn-inner/tomobo-push/logoweb.png"
    //   alt="logo"
    //   width="99"
    //   height="40"
    //   style={{ position: 'relative', top: -2 }}
    // />

    // <img
    //   src="https://d2nmg3qradgpe0.cloudfront.net/cdn-inner/tomobo-push/logoweb.png"
    //   alt="logo"
    //   width="99"
    //   height="40"
    //   style={{ position: 'relative', top: -2 }}
    // />
    <span/>
  )
}
