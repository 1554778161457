
const config: IConfig = {
  serve: `http://${window.location.hostname}/api`,
  servePath: `http://${window.location.hostname}`,
  keys: ['some secret hurr'],
  session: {
    key: 'koa:sess',
  },
}

export default config
