import { CREDENTIALS, serve } from './constant'

// 仓库
export default {
  fetchStructureModuleData(){
    return fetch(`${serve}/structure/module/list`, {...CREDENTIALS})
      .then(res=>res.json())
      .then(json => json.data)
  },
  addStructureModule(data:any) {
    return fetch(`${serve}/structure/module/create`, {
      ...CREDENTIALS,
      method: 'POST',
      body: JSON.stringify(data),
      headers: { 'Content-Type': 'application/json' },
    })
      .then(res=>res.json())
      .then(json => json.data)
  },
  removeStructureModule(data: any){
    return fetch(`${serve}/structure/module/remove`, {
        ...CREDENTIALS,
      method: 'POST',
      body: JSON.stringify(data),
      headers: { 'Content-Type': 'application/json' },
    })
    .then(res=>res.json())
    .then(json => json.data)
  },
  updateStructureModule(data: any){
    return fetch(`${serve}/structure/module/update`, {
        ...CREDENTIALS,
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' },
    })
    .then(res=>res.json())
    .then(json => json.data)
  },
  fetSingleStructure(id: number){
    return fetch(`${serve}/structure/get?id=${id}`, {...CREDENTIALS})
        .then(res=>res.json())
        .then(json => json.data)
  },
  createStructure(data: any) {
    return fetch(`${serve}/structure/create`, {
        ...CREDENTIALS,
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' },
    })
    .then(res=>res.json())
    .then(json => json.data)
  },
  removeStructure(data: any){
    return fetch(`${serve}/structure/remove`, {
        ...CREDENTIALS,
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' },
    })
    .then(res=>res.json())
    .then(json => json.data)
  },
  updateStructure(data: any){
    return fetch(`${serve}/structure/update`, {
        ...CREDENTIALS,
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' },
    })
    .then(res=>res.json())
    .then(json => json.data)
  },
  lockStructure(data: any) {
    return fetch(`${serve}/structure/lock`, {
        ...CREDENTIALS,
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' },
    })
    .then(res=>res.json())
    .then(json => json.data)
  },
  unlockStructure(data: any) {
    return fetch(`${serve}/structure/unlock`, {
        ...CREDENTIALS,
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' },
    })
    .then(res=>res.json())
    .then(json => json.data)
  },
  interfaceDetail(id: number) {
    return fetch(`${serve}/interface/get?id=${id}`, {...CREDENTIALS})
      .then(res=>res.json())
      .then(json => json.data)
  }
}
