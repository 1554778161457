// 获取结构体模块列表
export const fetchStructureModuleList = (onResolved?:any)=>({
    type: 'FETCH_STRUCTURE_MODULE_LIST',
    onResolved
})

// 新增模块
export const createStructureModule = (data: any, onResolved?: any) =>({
    type: 'CREATED_STRUCTURE_MODULE',
    data,
    onResolved
})

// 删除模块
export const removeStructureModule = (id: number, onResolved?: any)=>({
    type: 'REMOVE_STRUCTURE_MODULE',
    id,
    onResolved
})

// 修改模块
export const updateStructureModule = (data: any, onResolved?: any) => ({
    type: 'UPDATE_STRUCTURE_MODULE',
    data,
    onResolved
})


// 查询结构体
export const fetchSingleStructure = (id: any, onResolve?:any) => ({
    type: 'FETCH_SINGLE_STRUCTURE',
    id,
    onResolve
})

 // 增 name: string, description: string, moduleId: number
export const createStructure = (params: any, onResolve?: any)=>({
    type: 'CREATED_STRUCTURE',
    params,
    onResolve
})

// 删
export const removeStructure = (id: number, onResolve?: any)=>({
    type: 'REMOVE_STRUCTURE',
    id,
    onResolve
})

// 修改
export const updateStructure = (data: any, onResolve?: any)=>({
    type: 'UPDATA_STRUCTURE',
    data,
    onResolve
})

// 锁住
export const lockStructure = (id: any, onResolve?: any)=>({
    type: 'LOCK_STRUCTURE',
    id,
    onResolve
})

// 解锁
export const unlockStructure = (id: any, onResolve?: any)=>({
    type: 'UNLOCK_STRUCTURE',
    id,
    onResolve
})

// 接口详情
export const interfaceDetail = (id: number, onResolve?: any)=>({
    type: 'FETCT_STRUCTURE_INTERFACE_DETAIL',
    id,
    onResolve
})


export const setEditableState = (editor: boolean, onResolve?: any)=>({
    type: 'SET_STRUCTURE_EDITOR_STATE',
    editor
})

// 点击编辑
export const editorStructure = (structureId: number, onResolve?: any)=>({
    type: 'EDITABLE_STRUCTURE',
    structureId,
    onResolve
})

// 设置结构体数据
export const setStructureData = (data: any)=>({
    type: 'SET_STRUCTURE_DATE',
    data
})

// 点击保存
// export const saveStructur

// 是否显示结构体选择弹窗
export const setShowDialog = (visible: boolean, onResolve?: any)=>({
    type: 'SHOW_DIALOG_STRUCTURE',
    visible,
    onResolve
})


export const setControlStructureInfo = (data: any, onResolve?: any)=> ({
    type: 'CURRENT_CONTROL_STRUCTURE_INFO',
    data,
    onResolve
})