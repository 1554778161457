import { call, put, select } from 'redux-saga/effects'
import * as StructureAction from '../../actions/structure'
import { RootState } from 'actions/types'
import StructureService from '../services/structure'
import * as RepositoryAction from '../../actions/repository'
import { replace } from 'family'


export function* fetStructureData(action: any) {
    const result = yield call(StructureService.fetchStructureModuleData)
    yield put(RepositoryAction.fetchStructureData(result))
}

export function* createStructureModule(action: any) {
    try{
        const result = yield call(StructureService.addStructureModule,action.data )
        yield put(StructureAction.fetchStructureModuleList())
        const router = yield select((state: RootState) => state.router)
        const { pathname, hash, query } = router.location
        const {stm,modS, ...rest} = query
        const queryArr = Object.keys(rest).map(prop=>{
            return [prop, query[prop]]
        }).map(arr=>arr.join('='))
        yield put(replace(pathname + hash + `?${queryArr.join('&')}&modS=${result.id}`))
        action.onResolved && action.onResolved()
    }catch(e){
        console.error(e.message)
    }
}

export function* removeStructureModule(action: any){
    try{
        const result = yield call(StructureService.removeStructureModule, {id: action.id})
        const router = yield select((state: RootState) => state.router)
        const { pathname, hash, query } = router.location
        yield put(StructureAction.fetchStructureModuleList())
        let uri = `?id=${query.id}`
        query.mod && (uri += `&mod=${query.mod}`)
        query.itf && (uri += `&itf=${query.itf}`)
        yield put(replace(pathname + hash + uri))
        action.onResolved && action.onResolved()
    }catch(e){
        console.error(e.message)
    }
}

export function* updateStructureModule(action: any) {
    try{
        const result  = yield call(StructureService.updateStructureModule, action.data)
        action.onResolved && action.onResolved()
        yield put(StructureAction.fetchStructureModuleList())
    }catch(e){
        console.error(e.message)
    }
}

// 结构体部分
export function* createStructure(action: any){
    try{
        const result = yield call(StructureService.createStructure, action.params)
        action.onResolve && action.onResolve()
        yield put(StructureAction.fetchStructureModuleList())
        const router = yield select((state: RootState) => state.router)
        const { pathname, hash, query } = router.location
        const {stm, ...rest} = query
        const queryArr = Object.keys(rest).map(prop=>{
            return [prop, query[prop]]
        }).map(arr=>arr.join('='))
        yield put(replace(pathname + hash + `?${queryArr.join('&')}&stm=${result?.itf?.id}`))
    }catch(e){
        console.error(e.message)
    }
}

export function* removeStructure(action: any) {
    try{
        const result = yield call(StructureService.removeStructure, {id: action.id})
        action.onResolve && action.onResolve()
        yield put(StructureAction.fetchStructureModuleList())
    }catch(e){
        console.error(e.message)
    }
}

export function* updateStructure(action: any){
    try{
        const info = yield call(StructureService.fetSingleStructure, action.data.id)
        const {id} = yield select((state: RootState) => state.auth)
        if(info.lockerId && info.lockerId === id){
            yield call(StructureService.updateStructure, action.data)
            yield call(StructureService.unlockStructure, {id: action.data.id})
            action.onResolve && action.onResolve({isOk: true})
            yield put(StructureAction.fetchStructureModuleList())
        }
        else if(!info.lockerId){
            yield call(StructureService.updateStructure, action.data)
            action.onResolve && action.onResolve({isOk: true})
            yield put(StructureAction.fetchStructureModuleList())
        }
        else{
            action.onResolve && action.onResolve({isOk: false})
        }
    }catch(e){
        console.error(e.message)
    }
}

export function* editorStructure(action: any){
    try{
        const result = yield call(StructureService.fetSingleStructure, action.structureId)
        const {id} = yield select((state: RootState) => state.auth)
        if(result && (!result.lockerId || result.lockerId === id)){
            yield call(StructureService.lockStructure, {id: action.structureId})
            const structureList = yield select((state: RootState) => state.structureList)
            const newStr = structureList.map(mod=> {
                mod.list = mod.list.map(st=> {
                    if(st.id === action.structureId){
                        st = {
                            ...st,
                            ...result,
                            lockerId: id
                        }
                    }
                    return st
                })
                return mod // 122
            })
            yield put(StructureAction.setStructureData(newStr))
            // 可以编辑
            // yield put(StructureAction.setEditableState(true))
            action.onResolve && action.onResolve({isOk: true, properties: result.properties})
        }
        else{
            action.onResolve && action.onResolve({isOk: false})
        }
    }catch(e){
        console.error(e.message)
    }
}

export function* unlockStructure(action: any){
    try{
        const sInfo = yield call(StructureService.fetSingleStructure, action.id)
        const {id} = yield select((state: RootState) => state.auth)
        if(sInfo.lockerId && sInfo.lockerId !== id){
            action.onResolve && action.onResolve({isOk: false})
        }
        else if(sInfo.lockerId && sInfo.lockerId === id){
            yield call(StructureService.unlockStructure, {id: action.id})
            const structureList = yield select((state: RootState) => state.structureList)
            const newStr = structureList.map(mod=> {
                mod.list = mod.list.map(st=> {
                    if(st.id === action.id){
                        st = {
                            ...st,
                            ...sInfo,
                            lockerId: null
                        }
                    }
                    return st
                })
                return mod // 122
            })
            yield put(StructureAction.setStructureData(newStr))
            action.onResolve && action.onResolve({isOk: true})
        }
        else{
            action.onResolve && action.onResolve({isOk: true})
        }
        yield put(StructureAction.setEditableState(false))
    }catch(e){
        console.error(e.message)
    }
}